import React from "react";
import {
	useAcademiaFormState,
	ACADEMIA_FORM_STEPS,
} from "../_useAcademiaFormState";
import { AcademiaStepPage } from "../_AcademiaStepPage";
import { useApplicationContactPageFields } from "@components/ApplicationStepPage/common/Contact/useApplicationContactFields";

const AcademiaContact: React.FunctionComponent = () => {
	const formData = useAcademiaFormState(ACADEMIA_FORM_STEPS.CONTACT.formStep);
	const { body, onSubmit } = useApplicationContactPageFields(
		formData,
		"email@university.edu"
	);

	return (
		<AcademiaStepPage
			currentStepIndex={ACADEMIA_FORM_STEPS.CONTACT.formStep}
			onButtonClick={() =>
				onSubmit(() =>
					formData.navigateToStep(ACADEMIA_FORM_STEPS.TEAM.formStep)
				)
			}
		>
			{body}
		</AcademiaStepPage>
	);
};

export default AcademiaContact;
